/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Homepage from "./component/pages/Homepage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aboutpage from "./component/pages/Aboutpage";
import Servicespage from "./component/pages/Servicespage";
import Contactpage from "./component/pages/Contactpage";
import WebsiteDev from "./component/pages/WebsiteDev";
import ApplicationDev from "./component/pages/ApplicationDev";
import CloudServices from "./component/pages/CloudServices";
import ItConsultation from "./component/pages/ItConsultation";
import UiUxDesign from "./component/pages/UiUxDesign";
import AiML from "./component/pages/AiML";
import EcommerceBlog from "./component/pages/EcommerceBlog";
import AlMlBlog from "./component/pages/Al&MlBlog";
import PromtBlog from "./component/pages/PromtBlog";
import NotFoundPage from "./component/pages/404page";
import BlogPage from "./component/pages/BlogPage";
import PrivacyPolicy from "./component/pages/PrivacyPolicy";
import ScrollToTopButton from "./component/ScrollToTopButton";
import GoogleTagManager from "./GoogleTagManager";

function App() {
  return (
    <div>
      <GoogleTagManager />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/services" element={<Servicespage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/websiteDev" element={<WebsiteDev />} />
          <Route path="/applicationDev" element={<ApplicationDev />} />
          <Route path="/cloudServices" element={<CloudServices />} />
          <Route path="/itConsultation" element={<ItConsultation />} />
          <Route path="/uiUxDesign" element={<UiUxDesign />} />
          <Route path="/aiMl" element={<AiML />} />
          <Route path="/blog/e-commerce-blog" element={<EcommerceBlog />} />
          <Route path="/blog/ai-ml-blog" element={<AlMlBlog />} />
          <Route path="/blog/promt-blog" element={<PromtBlog />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ScrollToTopButton />
      </BrowserRouter>
    </div>
  );
}

export default App;
