import React from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";

function ItConsultation() {
  return (
    <div>
      <Header />
      <div className="container servicesWrraper">
        <div data-aos="fade-up" data-aos-delay="300">
          <div>
            <div className="serviceSectionHeader">
              IT Consultation Services!
            </div>
          </div>
          <h3>
            In an increasingly digital world, expert IT guidance is the compass
            for your business's success. Toplo Technology is your trusted
            partner, providing insights, strategies, and solutions to navigate
            the complex IT landscape.
          </h3>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Our Approach</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/mobile-application.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Comprehensive IT Audits</div>
                  <div className="colCardText">
                    In-depth assessments of your IT infrastructure.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Tailored IT Roadmaps</div>
                  <div className="colCardText">
                    Customized strategies for your business's unique needs.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintain-software.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Continuous Support</div>
                  <div className="colCardText">
                    Ongoing consultation to keep your IT aligned with your
                    goals.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Technology Stack</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-infrastructure.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cybersecurity Protocols</div>
                  <div className="colCardText">
                    Protect your data and systems from threats.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Data Analytics Tools</div>
                  <div className="colCardText">
                    Uncover valuable insights from your data.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Automation Solutions</div>
                  <div className="colCardText">
                    Streamline processes for efficiency.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-modernization.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cloud Integration</div>
                  <div className="colCardText">
                    Seamlessly incorporate the power of the cloud.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Benefits</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/blockchain.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Enhanced Efficiency</div>
                  <div className="colCardText">
                    Streamline operations and reduce downtime.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-sass.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">
                    Data-Driven Decision-Making
                  </div>
                  <div className="colCardText">
                    Leverage insights for strategic choices.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Robust Security</div>
                  <div className="colCardText">
                    Protect your valuable data and systems.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cost Optimization</div>
                  <div className="colCardText">
                    Ensure your IT investment is cost-effective.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Why Choose Us</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Latest Technologies</div>
                  <div className="colCardText">
                    Tailoring strategies to your business's needs.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Powerful Strategies</div>
                  <div className="colCardText">
                    Realize the full potential of IT in your operations.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Delivery Just On Time</div>
                  <div className="colCardText">
                    We commit to meeting project deadlines consistently
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3>
          Navigate the digital landscape with confidence by partnering with
          Toplo Technology for IT Consultation Services. Let our expertise guide
          your IT strategies, enhance efficiency, and secure your systems to
          drive your business's success
        </h3>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default ItConsultation;
