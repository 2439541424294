import React from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";

function WebsiteDev() {
  return (
    <div>
      <Header />
      <div className="container servicesWrraper">
        <div data-aos="fade-up" data-aos-delay="300">
          <div>
            <div className="serviceSectionHeader">
              Web Development services!
            </div>
          </div>
          <h3>
            We are committed to crafting web solutions that transform your
            digital presence. Our experienced team leverages the latest
            technologies and embraces innovative techniques to develop robust
            and dynamic websites.
          </h3>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">The Benefits</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-design.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Scalability</div>
                  <div className="colCardText">
                    We engineer your web solutions to scale with your business
                    needs, ensuring your site can grow alongside you.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">User Experience</div>
                  <div className="colCardText">
                    Our UI/UX design ensures a seamless and intuitive navigation
                    experience for your visitors.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Security</div>
                  <div className="colCardText">
                    We prioritize your site's security with advanced measures
                    and encryption protocols to safeguard your data.
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-website.svg"
                      alt="security"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Performance</div>
                  <div className="colCardText">
                    Our sites load faster, improving user satisheadfaction and
                    search engine rankings.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-application.svg"
                      alt="maintenance"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Customization</div>
                  <div className="colCardText">
                    We tailor every website to meet your unique requirements,
                    ensuring a personalized and branded online presence.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-design.svg"
                      alt="optimization"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Responsive Design</div>
                  <div className="colCardText">
                    With a focus on mobile-first design, your website will look
                    and perform flawlessly on all devices.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-infrastructure.svg"
                      alt="optimization"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">E-commerce Integration</div>
                  <div className="colCardText">
                    For businesses, we offer e-commerce solutions that help you
                    expand your digital storefront effectively.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-launching.svg"
                      alt="optimization"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">SEO Optimization</div>
                  <div className="colCardText">
                    We structure sites with SEO in mind, ensuring your content
                    ranks well on search engines.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="expertizeSection">
          <div data-aos="fade-up" data-aos-delay="300">
            <div>
              <div className="serviceSectionHeader">Our Expertise </div>
            </div>
            <h3>
              We excel in a variety of technologies including Node.js, Angular,
              React.js, Next.js, WordPress, Nuxt.js, Express.js, and JavaScript.
              These technologies form the building blocks of responsive and
              feature-rich websites.
            </h3>
          </div>
        </div>
        <div className="expertizeSection">
          <div data-aos="fade-up" data-aos-delay="300">
            <div>
              <div className="serviceSectionHeader">Why Us </div>
            </div>
            <h3>
              Our portfolio boasts an array of successful web development
              projects across various industries, and we've transformed many
              small ideas into grand online realities. We understand the
              intricacies of each technology we work with and make them work to
              your advantage.
            </h3>
            <h3>
              When you choose us for web development, you're not just getting a
              service; you're gaining a digital partner committed to your
              success. We look forward to collaborating with you to turn your
              web dreams into reality. Let's build an impactful online presence
              that captivates your audience and drives your digital success
              story.
            </h3>
          </div>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Why Choose Us</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Latest Technologies</div>
                  <div className="colCardText">
                    We stay ahead of the curve, utilizing the most cutting-edge
                    technologies to ensure your web development aligns with
                    industry trends.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-implementation.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Unique Solutions</div>
                  <div className="colCardText">
                    We don't believe in one-size-fits-all. Our team crafts
                    customized web solutions tailored to your specific needs and
                    goals.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Powerful Strategies</div>
                  <div className="colCardText">
                    Our experts employ dynamic strategies that boost your online
                    presence and help you stand out in the digital landscape.
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="security"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Delivery Just On Time</div>
                  <div className="colCardText">
                    We understand that time is of the essence. Our commitment to
                    on-time project delivery ensures your web solution is in
                    your hands precisely when you need it.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default WebsiteDev;
