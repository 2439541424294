import React, { useEffect } from "react";
import Header from "../../headerComponent/header";
import "./services.css";
import FooterComponent from "../../footerComponent";
import ParticlesComponent from "../../particles";

function Servicespage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ParticlesComponent />

      <Header />
      <div className="container servicesWrraper">
        <section id="services-section">
          <div className="pageIntroDiv">
            <div className="serviceSectionHeader">Services We Offer</div>
            <div className="introDivDiv">
              <h4>
                Elevate your business with our comprehensive suite of tech
                solutions. From web development to AI-driven apps, we craft
                digital experiences that empower your brand.
              </h4>
            </div>
          </div>
          <div className="row  cardRowWrraper">
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/application-development.svg"
                    alt="marketing"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Application Development</div>
                <div className="colCardText">
                  Turning your concepts into stunning applications. Our
                  application development services create seamless, engaging
                  apps for your digital journey.
                </div>
                <a
                  href="/applicationDev"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn2"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/webservice-website.svg"
                    alt="E-commerce Solutions"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Website Development</div>
                <div className="colCardText">
                  Transforming your ideas into exceptional web experiences. Our
                  web development services deliver innovative, user-friendly
                  solutions for your online success.
                </div>
                <a
                  href="/websiteDev"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/web-design.svg"
                    alt="maintenance"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">UI/UX Design</div>
                <div className="colCardText">
                  Elevate user experiences with captivating design. Our UI-UX
                  services craft interfaces that are both beautiful and
                  functional. Designing seamless user experiences, one pixel at
                  a time.
                </div>
                <a
                  href="/uiUxDesign"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn5"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img src="/ai.svg" alt="optimization" className="cardImage" />
                </div>
                <div className="colCardTitle">AI & ML</div>
                <div className="colCardText">
                  Unlock the power of AI & ML for smarter solutions. Our
                  expertise in artificial intelligence and machine learning
                  creates cutting-edge possibilities.""AI & ML: Shaping a
                  smarter tomorrow with data-driven decisions.
                </div>
                <a
                  href="/aiMl"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn6"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/cloud-development.svg"
                    alt="webDesign"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Cloud Services</div>
                <div className="colCardText">
                  Elevating your operations to the cloud. Our cloud services
                  provide scalable, secure solutions for a brighter digital
                  future. Taking your operations to new heights with our
                  reliable cloud services.
                </div>
                <a
                  href="/cloudServices"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn3"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/maintain-software.svg"
                    alt="security"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">IT Consultation</div>
                <div className="colCardText">
                  Guiding your tech choices for optimal results. Our IT
                  consultation services provide expert insights for smarter,
                  more efficient solutions.
                </div>
                <a
                  href="/itConsultation"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn4"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default Servicespage;
