import React from "react";
import { Link } from "react-router-dom";

function FooterComponent() {
  return (
    <div>
      <div className="footerDiv">
        <Link className="navbar-brand" to="/">
          <img className="FooterImage" src="/toplologo.png" alt="ReactImage" />
        </Link>
        <div>
          <h6>
            We are a team of enthusiasts, driven by relentless hard work and
            boundless creativity, dedicated to shaping innovative solutions for
            your success.
          </h6>
        </div>

        <div className="footerLinks">
          <Link className="nav-link" to="/">
            Home
          </Link>
          <Link className="nav-link" to="/about">
            About Us
          </Link>
          <Link className="nav-link" to="/services">
            Our Services
          </Link>
          <Link className="nav-link" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="nav-link" to="/contact">
            Contact Us
          </Link>
          <Link className="nav-link" to="/blog">
            Blog
          </Link>
        </div>
        <div className="companyDetailsDiv row">
          <div className="col">
            <h5>Contact Us: (+91)8320418597 |toplotech.services@gmail.com</h5>
          </div>
          <div className="col locationWrraper">
            <div className="locationDiv">
              <img
                src="/location.png"
                alt="location"
                className="locationImage"
              />
            </div>
            <div className="locationText">
              B-606, Decora 9 Square Building, Near Nana Mava Circle, Rajkot,
              India.
            </div>
          </div>
        </div>

        <div className="footercopyright">
          <div>© 2023 Toplo Technology. All Rights Reserved</div>
          <div className="socialMediaLinks">
            <a
              target="blank"
              href="https://www.linkedin.com/company/toplo-technology/posts/?feedView=all"
            >
              <img src="/linkedin.png" alt="linkedin" />
            </a>
            <a
              target="blank"
              href="https://www.instagram.com/toplo_technology/"
            >
              <img src="/insta.png" alt="insta" />
            </a>
            <a target="blank" href="https://www.facebook.com/">
              <img src="/fb.png" alt="fb" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
