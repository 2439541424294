import React, { useEffect } from "react";
import FooterComponent from "../../footerComponent";
import Header from "../../headerComponent/header";
import "./privacy.css";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div className="container policyWrraper">
        <h2>
          At Toplo Technology, we take your privacy seriously. This Privacy
          Policy is designed to help you understand how we collect, use,
          disclose, and safeguard your personal information. By using our
          website, you agree to the terms outlined in this policy.
        </h2>
        <div className="privacyIntroDiv">
          <div className="serviceSectionHeader">Information We Collect</div>
          <div className="privacyText">
            <h4>We may collect the following information</h4>
          </div>
          <ul>
            <li>
              Personal Information: When you visit our website or interact with
              our services, you may voluntarily provide personal information
              such as your name, email address, phone number, and other contact
              details.
            </li>
            <li>
              Usage Information: We collect non-personal information about how
              you use our website. This may include your IP address, browser
              type, device information, and pages visited.
            </li>
            <li>
              Cookies: We use cookies to enhance your browsing experience and to
              collect data about how you use our website. You can manage your
              cookie preferences through your browser settings.
            </li>
          </ul>
        </div>
        <div className="privacyIntroDiv">
          <div className="serviceSectionHeader">
            How We Use Your Information
          </div>
          <div className="privacyText">
            <h4>We use your information for the following purposes</h4>
          </div>
          <ul>
            <li>To provide and improve our website and services.</li>
            <li>
              To send you updates, marketing communications, and respond to your
              inquiries.
            </li>
            <li>To analyze website usage and trends.</li>
            <li>Disclosure of Your Information.</li>
          </ul>
        </div>
        <div className="privacyIntroDiv">
          <div className="serviceSectionHeader">
            We may share your information with
          </div>
          <div className="privacyText">
            <h4>
              We use reasonable security measures to protect your information.
              However, no method of transmission or electronic storage is 100%
              secure.
            </h4>
          </div>
          <ul>
            <li>
              Service providers who help us deliver our services. Law
              enforcement or government authorities in response to a legal
              request. Your Choices
            </li>
            <li>
              You have the right to: Access, correct, or delete your personal
              information. Opt-out of receiving marketing communications. Data
              Security
            </li>
          </ul>
        </div>
        <div className="privacyIntroDiv">
          <div className="serviceSectionHeader">Changes to This Policy</div>
          <div className="privacyText">
            <h4>
              This Privacy Policy may be updated periodically. We will notify
              you of significant changes through our website or other means.
            </h4>
          </div>
        </div>
        <div className="privacyIntroDiv">
          <div className="serviceSectionHeader">Contact Us</div>
          <div className="privacyText">
            <h4>
              If you have questions or concerns about this Privacy Policy,
              please contact us at{" "}
              <a href="mailto:toplotech.services@gmail.com">Email</a>
            </h4>
          </div>
        </div>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
