import React, { useEffect } from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";
import ParticlesComponent from "../../particles";

function Contactpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <ParticlesComponent />

      <div className="container servicesWrraper">
        <div className="pageIntroDiv">
          <div className="serviceSectionHeader">Work With Us</div>
          <div className="introDivDiv">
            <h4>
              Join our passionate team of tech enthusiasts. If you're driven by
              innovation and love a dynamic work environment, we want to hear
              from you. Together, we create the future.
            </h4>
          </div>
        </div>
        <section id="contact-section" className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <div>
                <img
                  src="/findClient.png"
                  alt="findClients"
                  className="businessImage"
                />
                <div className="bgImage">
                  <div className="bgImageTitle">
                    Do We Smell a Business Brewing?
                  </div>
                  <div className="bgImageText">Tell us about it!</div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 formWrraper">
              <div>
                <div className="formTitle">
                  Let’s get your dream complete...
                </div>
              </div>
              <form className="formArea">
                <div className="row">
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Your Name*
                    </label>
                    <input
                    style={{color:"white" }}
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Your Email*
                    </label>
                    <input
                    style={{color:"white" }}
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Company Name*
                    </label>
                    <input
                    style={{color:"white" }}
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Phone Number*
                    </label>
                    <input
                      style={{color:"white" }}
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px",color:"white" }}
                  ></textarea>
                  <label for="floatingTextarea2">
                    Tell us about your project*
                  </label>
                </div>
                <button
                  type="submit"
                  class=" btn btn-primary"
                  className="submitBtn"
                >
                  Get Started
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default Contactpage;
