import React, { useEffect } from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";
import "./aboutpage.css";
import ParticlesComponent from "../../particles";

function Aboutpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ParticlesComponent />

      <Header />
      <div className="container aboutWrraper">
        <div className="pageIntroDiv">
          <div className="serviceSectionHeader">Who We Are</div>
          <div className="introDivDiv">
            <h4>
              Toplo Technology is more than a company; it's a group of tech
              aficionados driven by a shared passion for innovation and client
              success. We come from diverse backgrounds but share a common goal
              - to make a meaningful impact through technology.
            </h4>
          </div>
        </div>

        <div className="row rowCardDesign">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 firstOrder">
            <img src="./vision.png" className="colImage" alt="vision" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 secondOrder">
            <div className="colTitle">Our Vision</div>
            <div className="colText">
              At Toplo Technology, our vision transcends the present and is
              rooted in the future. We envision a world where technology
              empowers businesses to excel. We strive to be at the forefront of
              this transformation, catalyzing growth, and fueling innovation for
              our clients.
            </div>
          </div>
        </div>
        <div className="row rowCardDesign">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="colTitle">Our Mission</div>
            <div className="colText">
              Our mission is clear - to be your trusted partner in the digital
              realm. We're on a mission to empower enterprises by providing them
              with cutting-edge technology solutions. We believe in the
              transformative power of technology and work relentlessly to ensure
              that our clients unlock their full potential.
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <img src="./mission.png" className="colImage" alt="mission" />
          </div>
        </div>
        <div className="row rowCardDesign">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 firstOrder">
            <img src="./whatdo.png" className="colImage" alt="whatdo" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 secondOrder">
            <div className="colTitle">What We Do</div>
            <div className="colText">
              Our expertise spans a wide spectrum of technology services. From
              web and application development to advanced AI solutions, we offer
              a comprehensive suite of tech services. We blend creativity with
              technology to deliver solutions that power your business into the
              future.
            </div>
          </div>
        </div>
        <div className="row rowCardDesign">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="colTitle">Our Values</div>
            <div className="colText">
              Our values define us. They are the cornerstones of our company
              culture. We are committed to delivering excellence, encouraging
              innovation, and maintaining the highest levels of integrity and
              professionalism in every aspect of our business.
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <img src="./value.png" className="colImage" alt="value" />
          </div>
        </div>
        <div className="row rowCardDesign">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 firstOrder">
            <img src="./whyus.png" className="colImage" alt="whyus" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 secondOrder">
            <div className="colTitle">Why Choose Us</div>
            <div className="colText">
              We Turn Dream into Digital Realities At Toplo Technology, we're
              more than just tech enthusiasts; we're your dedicated problem
              solvers. Our journey began with a simple yet profound idea: to
              harness the potential of technology and make it accessible to
              businesses of all sizes and industries. Today, we proudly serve
              clients worldwide, helping them thrive in the ever-evolving
              digital landscape.
            </div>
          </div>
        </div>
        <div>
          <h1>Get in Touch</h1>
          <h4>
            Ready to embark on your journey to digital excellence? Get in touch
            with us today. Together, we'll unlock the full potential of your
            business through technology.
          </h4>
        </div>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default Aboutpage;
