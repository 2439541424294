import React, { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    const initGTM = () => {
      // Initialize window.dataLayer
      window.dataLayer = window.dataLayer || [];

      function gtag(...args) {
        window.dataLayer.push(args);
      }

      gtag("js", new Date());
      gtag("config", "AW-689221597");
    };

    initGTM();
  }, []);

  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-689221597"
      />
    </>
  );
};

export default GoogleTagManager;
