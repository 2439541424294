import React, { useEffect } from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";
import { useNavigate } from "react-router-dom";
import Particles from "../../particles";

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <section className="container heroSection">
        <Particles />
        <div className="row heroWrraper">
          <div className="col-xl-6 col-lg-6 col-md-12  col-12">
            <div className="heroText">
              Welcome to Toplo Technology{" "}
              <span>Unleash the Power of Technology</span>
            </div>
            <button className="exploreBtn" onClick={() => navigate("/about")}>
              Explore More
            </button>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12  col-12">
            <div style={{ width: "100%" }}>
              <img src="/hero.png" alt="hero" className="heroImage" />
            </div>
          </div>
        </div>
      </section>
      <section id="services-section">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="introHeadingDiv">
            <div className="serviceSectionHeader">Services We Offer</div>
          </div>
          <div className="row  cardRowWrraper">
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/application-development.svg"
                    alt="marketing"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Application Development</div>
                <div className="colCardText">
                  Turning your concepts into stunning applications. Our
                  application development services create seamless, engaging
                  apps for your digital journey.
                </div>
                <a
                  href="/applicationDev"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn2"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/webservice-website.svg"
                    alt="E-commerce Solutions"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Website Development</div>
                <div className="colCardText">
                  Transforming your ideas into exceptional web experiences. Our
                  web development services deliver innovative, user-friendly
                  solutions for your online success.
                </div>
                <a
                  href="/websiteDev"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/web-design.svg"
                    alt="maintenance"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">UI/UX Design</div>
                <div className="colCardText">
                  Elevate user experiences with captivating design. Our UI-UX
                  services craft interfaces that are both beautiful and
                  functional. Designing seamless user experiences, one pixel at
                  a time.
                </div>
                <a
                  href="/uiUxDesign"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn5"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img src="/ai.svg" alt="optimization" className="cardImage" />
                </div>
                <div className="colCardTitle">AI & ML</div>
                <div className="colCardText">
                  Unlock the power of AI & ML for smarter solutions. Our
                  expertise in artificial intelligence and machine learning
                  creates cutting-edge possibilities.""AI & ML: Shaping a
                  smarter tomorrow with data-driven decisions.
                </div>
                <a
                  href="/aiMl"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn6"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/cloud-development.svg"
                    alt="webDesign"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">Cloud Services</div>
                <div className="colCardText">
                  Elevating your operations to the cloud. Our cloud services
                  provide scalable, secure solutions for a brighter digital
                  future. Taking your operations to new heights with our
                  reliable cloud services.
                </div>
                <a
                  href="/cloudServices"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn3"
                >
                  Read more
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-12"
              style={{ marginBottom: "30px" }}
            >
              <div className="colCardWrraper">
                <div className="cardIconStyle">
                  <img
                    src="/maintain-software.svg"
                    alt="security"
                    className="cardImage"
                  />
                </div>
                <div className="colCardTitle">IT Consultation</div>
                <div className="colCardText">
                  Guiding your tech choices for optimal results. Our IT
                  consultation services provide expert insights for smarter,
                  more efficient solutions.
                </div>
                <a
                  href="/itConsultation"
                  style={{ textDecoration: "none" }}
                  className="knowMoreBtn4"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div data-aos="fade-up" data-aos-delay="100">
          <div style={{ marginTop: "100px" }}>
            <div className="sectionHeader">Why Choose Us</div>
          </div>
          <div
            className="sectionText textCenter"
            style={{ color: "white", fontWeight: 700 }}
          >
            We Turn Dream into Digital Realities
          </div>
          <div className="sectionText textCenter">
            At Toplo Technology, we're more than just tech enthusiasts; we're
            your dedicated problem solvers. Our journey began with a simple yet
            profound idea to harness the potential of technology and make it
            accessible to businesses of all sizes and industries.
          </div>
          <button
            className="showcaseProjectBtn"
            onClick={() => navigate("./about")}
          >
            Explore More
          </button>
          <div>
            <div className="row aboutProjectDiv">
              <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                <div className="aboutProjectText">
                  Your Trusted Partner for Bespoke Web Solutions
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-12 getInbtnColWrraper">
                <button
                  className="getInTouchBtn"
                  onClick={() => navigate("./contact")}
                >
                  Let's Talk
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container voiceOfSatisfactionwrraper">
        <div data-aos="fade-up" data-aos-delay="100">
          <div className="voiceofSatisfactionSection">
            <div className="voiceofSatisfactionSubSection">
              <div>
                <div className="sectionHeader">Voices of Satisfaction</div>
              </div>
              <div className="voiceSectionImage">
                <img src="/voiceImage.png" alt="voiceImage" />
              </div>
            </div>
          </div>
          <div className="reviewWrraperDiv">
            <div className="row mainReviewWrraper">
              <div className="col-md-3 col-12 ">
                <img src="/client1.png" alt="freelance" />
                <div className="clientName">Jane Smith</div>
                <div className="clientPosition">Co-founder & CEO</div>
              </div>
              <div className="col-md-9 col-12 ">
                <div className="clientReviewWrraper">
                  <div>
                    <img src="qoute.png" alt="qoute" className="qoute" />
                  </div>
                  <div className="clientReview">
                    Toplo Technology has been working to keep our website
                    seamlessly functional and aesthetically beautiful. They have
                    been responsive to every step of the way and easy to reach
                    for any request. I had so many options available that I
                    could dream up anything that suits my needs. Happy with
                    Toplo Technology!
                  </div>
                </div>
              </div>
            </div>
            <div className="row mainReviewWrraper">
              <div className="col-md-9 col-12 firstOrder">
                <div className="clientReviewWrraper">
                  <div className="clientReview">
                    I worked with Toplo Technology team on the redesign of my
                    store. They brought the store to life, adding new navigation
                    and functionality to my site. They have great communications
                    skills, meet their deadlines on time and provide a fantastic
                    visual UX/UI design to any product category. I intend to
                    work with this team again and would highly recommend them.
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 secondOrder">
                <img src="/client2.png" alt="freelance" />
                <div className="clientName">David Anderson</div>
                <div className="clientPosition">Marketing Manager</div>
              </div>
            </div>
            <div className="row mainReviewWrraper">
              <div className="col-md-3 col-12 ">
                <img src="/client3.png" alt="freelance" />
                <div className="clientName">Sara Thompson</div>
                <div className="clientPosition">Co-founder & CEO</div>
              </div>
              <div className="col-md-9 col-12 ">
                <div className="clientReviewWrraper">
                  <div className="clientReview">
                    The team at Toplo Technology was quick to respond, great
                    about changing things when asked, and I love the way the
                    site came out for my client! Highly recommend!!!
                  </div>
                  <div>
                    <img src="qoute2.png" alt="qoute" className="qoute" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="blog-section" className="serviceBottom container">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="introHeadingDiv">
            <div className="serviceSectionHeader">Explore Our Blog</div>
          </div>
          <div className="row blogRowWrraper">
            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
              <div className="blogImageDiv">
                <img
                  src="/blogImage.png"
                  alt="blogImage"
                  className="blogImageStyle"
                />
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-12">
              <div className="blogText">
                Stay ahead with our captivating articles on technology trends,
                web solutions and expert insights. Fuel creativity, thrive inh
                the digital realm. Join us now!
              </div>
            </div>
          </div>
          <div className="row gx-5 mb-5 blogDivWrraper">
            <div className="col-md-4 col-12">
              <img src="/blog1.png" alt="blog" className="blogImageStyle" />

              <div className="blogTitle">
                The Future of E-Commerce: Unveiling Tomorrow's Shopping
                Experience
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/e-commerce-blog")}
              >
                READ MORE
              </button>
            </div>
            <div className="col-md-4 col-12">
              <img src="/blog2.png" alt="blog" className="blogImageStyle" />
              <div className="blogTitle">
                AI and Machine learning: Shaping Tomorrow's World, Today
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/ai-ml-blog")}
              >
                READ MORE
              </button>
            </div>
            <div className="col-md-4 col-12">
              <img src="/blog3.png" alt="blog" className="blogImageStyle" />
              <div className="blogTitle">
                Mastering the Art of Prompt Engineering: A Skill for the AI & ML
                Era
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/promt-blog")}
              >
                READ MORE
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div
          className="row joinTeamRowWrraper"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-md-8 col-12">
            <div className="joinTealTitle">
              <span>Magic</span> happens when we join forces!
            </div>
            <div className="joinTealText">
              We have an ambitious road ahead, and we’re looking for people to
              join our team to help shape the future of Toplo Technology.
            </div>
            <button
              className="joinTheTeamBtn"
              onClick={() => navigate("/contact")}
            >
              Join the team
            </button>
          </div>
          <div className="col-md-4 col-12">
            <div>
              <img
                src="/joinTeam.png"
                alt="join the team"
                className="joinTeamImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="contact-section" className="container">
        <div
          className="formWrraperSection"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
              <div>
                <img
                  src="/findClient.png"
                  alt="findClients"
                  className="businessImage"
                />
                <div className="bgImage">
                  <div className="bgImageTitle">
                    Do We Smell a Business Brewing?
                  </div>
                  <div className="bgImageText">Tell us about it!</div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 formWrraper">
              <div>
                <div className="formTitle">
                  Let’s get your dream complete...
                </div>
              </div>
              <form className="formArea">
                <div className="row">
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Your Name*
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Your Email*
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Company Name*
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="col mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Phone Number*
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label for="floatingTextarea2">
                    Tell us about your project*
                  </label>
                </div>
                <button
                  type="submit"
                  class=" btn btn-primary"
                  className="submitBtn"
                >
                  Get Started
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <FooterComponent />
      </section>
    </div>
  );
}

export default Homepage;
