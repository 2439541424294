import React from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";

function UiUxDesign() {
  return (
    <div>
      <Header />
      <div className="container servicesWrraper">
        <div data-aos="fade-up" data-aos-delay="300">
          <div>
            <div className="serviceSectionHeader">UI/UX Design!</div>
          </div>
          <h3>
            User Interface and User Experience design play a pivotal role in the
            success of any digital product. At Toplo Technology, we are
            dedicated to crafting intuitive and visually appealing designs that
            captivate and engage users
          </h3>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Our Approach</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/software-product-development.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">User-Centered Design</div>
                  <div className="colCardText">
                    Placing your audience's needs and preferences at the
                    forefront.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-design.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Wireframes and Prototyping</div>
                  <div className="colCardText">
                    Ensuring seamless navigation and interaction.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-design.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Aesthetically Pleasing</div>
                  <div className="colCardText">
                    Designs that marry style with function.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Technology Stack</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-design.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Adobe XD, Sketch, Figma</div>
                  <div className="colCardText">
                    Leveraging top-notch design tools.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-design.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Responsive Design</div>
                  <div className="colCardText">
                    Ensuring your app looks stunning on any device.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-modernization.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Motion Graphics</div>
                  <div className="colCardText">
                    Adding interactivity and visual interest.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintain-software.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">
                    Cross-Platform Compatibility
                  </div>
                  <div className="colCardText">
                    Appealing designs on every device.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Why Choose Us</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Latest Technologies</div>
                  <div className="colCardText">
                    Staying up-to-date with design tools and trends.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Powerful Strategies</div>
                  <div className="colCardText">
                    Enhancing user engagement and conversion.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Delivery Just On Time</div>
                  <div className="colCardText">
                    Meeting your project milestones effectively
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-implementation.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Unique Solutions</div>
                  <div className="colCardText">
                    Tailored to your brand and user preferences
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3>
          Elevate your digital presence with top-tier UI/UX design from Toplo
          Technology, where your user's journey begins with a beautiful and
          engaging interface.
        </h3>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default UiUxDesign;
