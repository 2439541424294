import React, { useEffect } from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";
import { useNavigate } from "react-router-dom";
import ParticlesComponent from "../../particles";

function BlogPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <ParticlesComponent />

      <div className="container servicesWrraper">
        <section id="blog-section" className="serviceBottom container">
          <div className="pageIntroDiv">
            <div className="serviceSectionHeader">Explore Our Blog</div>
            <div className="introDivDiv">
              <h4>
                Welcome to our knowledge hub! Explore insightful articles that
                unravel the latest in tech trends, digital strategies, and
                innovation. Dive into the world of possibilities.
              </h4>
            </div>
          </div>
          <div className="row blogRowWrraper">
            <div className="col-xl-3 col-lg-3 col-md-3 col-12">
              <div className="blogImageDiv">
                <img
                  src="/blogImage.png"
                  alt="blogImage"
                  className="blogImageStyle"
                />
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-9 col-12">
              <div className="blogText">
                Stay ahead with our captivating articles on technology trends,
                web solutions and expert insights. Fuel creativity, thrive inh
                the digital realm. Join us now!
              </div>
            </div>
          </div>
          <div className="row gx-5 mb-5 blogDivWrraper">
            <div className="col-md-4 col-12">
              <img src="/blog1.png" alt="blog" className="blogImageStyle" />

              <div className="blogTitle">
                The Future of E-Commerce: Unveiling Tomorrow's Shopping
                Experience
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/e-commerce-blog")}
              >
                READ MORE
              </button>
            </div>
            <div className="col-md-4 col-12">
              <img src="/blog2.png" alt="blog" className="blogImageStyle" />
              <div className="blogTitle">
                AI and Machine learning: Shaping Tomorrow's World, Today
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/ai-ml-blog")}
              >
                READ MORE
              </button>
            </div>
            <div className="col-md-4 col-12">
              <img src="/blog3.png" alt="blog" className="blogImageStyle" />
              <div className="blogTitle">
                Mastering the Art of Prompt Engineering: A Skill for the AI & ML
                Era
              </div>
              <button
                className="readMoreBtn"
                onClick={() => navigate("/blog/promt-blog")}
              >
                READ MORE
              </button>
            </div>
          </div>
        </section>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default BlogPage;
