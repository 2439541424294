import React, { useEffect, useState } from "react";
import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { Link } from "react-scroll";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  // console.log("activeLink", activeLink);

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  useEffect(() => {
    const currentRoute = location.pathname;
    setActiveLink(currentRoute);
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light headerWrraper">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img className="logoImage" src="/toplologo.png" alt="ReactImage" />
        </Link>
        <button
          className="navbar-toggler"
          id="navbarMenu"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="navbar-nav ms-auto headerRouterDiv">
            <li className="nav-item">
              <Link
                to="/"
                // onClick={(e) => handleLinkClick("/", e)}
                style={{
                  color: activeLink === "/" ? "#7c7af7" : "",
                  backgroundColor: "black",
                }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                // onClick={(e) => handleLinkClick("/about", e)}
                style={{ color: activeLink === "/about" ? "#7c7af7" : "" }}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/services"
                // onClick={(e) => handleLinkClick("/services", e)}
                style={{ color: activeLink === "/services" ? "#7c7af7" : "" }}
              >
                Our Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                // onClick={(e) => handleLinkClick("/contact", e)}
                style={{ color: activeLink === "/contact" ? "#7c7af7" : "" }}
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/blog"
                style={{ color: activeLink === "/blog" ? "#7c7af7" : "" }}
              >
                Blog
              </Link>
            </li>
          </div>
          <button className="headerBtn" onClick={() => navigate("/contact")}>
            Let's Talk
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
