import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

function ParticlesComponent() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            fullScreen: false,
            zIndex: -1,
          },
          name: "Snow",
          particles: {
            number: {
              value: 400,
              density: {
                enable: true,
              },
            },
            color: {
              value: "#fff",
            },
            shape: {
              type: "circle",
            },
            opacity: {
              value: 0.2,
            },
            size: {
              value: 10,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "bottom",
              straight: true,
            },
            wobble: {
              enable: true,
              distance: 10,
              speed: 10,
            },
            zIndex: {
              value: {
                min: 0,
                max: 100,
              },
              opacityRate: 10,
              sizeRate: 10,
              velocityRate: 10,
            },
          },
          background: {
            color: "#0e0e0e",
          },
        }}
      />
    </div>
  );
}

export default ParticlesComponent;
