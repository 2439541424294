import React from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";

function ApplicationDev() {
  return (
    <div>
      <Header />
      <div className="container servicesWrraper">
        <div data-aos="fade-up" data-aos-delay="300">
          <div>
            <div className="serviceSectionHeader">Application Development!</div>
          </div>
          <h3>
            In today's digital age, applications are the backbone of a seamless
            user experience. At Toplo Technology, we are committed to delivering
            top-tier application development services that align with your
            business objectives and user expectations.
          </h3>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Our Approach</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-management.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Full-Cycle Development</div>
                  <div className="colCardText">
                    We cover everything from ideation and design to development
                    and maintenance.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-modernization.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">User-Centered Design</div>
                  <div className="colCardText">
                    Our UI/UX experts ensure your app is not just functional but
                    a joy to use.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-testing.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Scalable Solutions</div>
                  <div className="colCardText">
                    We build applications ready to grow with your success.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Technology Stack</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/android.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">
                    Android, iOS, Flutter, React Native
                  </div>
                  <div className="colCardText">
                    Android, iOS, Flutter, React Native: Crafting versatile apps
                    for diverse platforms.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/python.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Python</div>
                  <div className="colCardText">
                    For robust, scalable, and high-performance applications.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/blockchain.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Blockchain</div>
                  <div className="colCardText">
                    Implementing decentralized solutions.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img src="/ai.svg" alt="webDesign" className="cardImage" />
                  </div>
                  <div className="colCardTitle">AI and ML</div>
                  <div className="colCardText">
                    Powering intelligent and data-driven applications.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/cloud-development.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cloud Computing</div>
                  <div className="colCardText">
                    Ensuring flexibility, scalability, and security.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Benefits</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-web-portal.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Versatility</div>
                  <div className="colCardText">
                    We're fluent in multiple programming languages and
                    platforms.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cutting-Edge</div>
                  <div className="colCardText">
                    Your app will leverage the latest tech trends.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-design.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Scalability</div>
                  <div className="colCardText">
                    Ready for growth and increased demands.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Security</div>
                  <div className="colCardText">
                    Robust solutions that keep your data and user information
                    safe.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Why Choose Us</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Latest Technologies</div>
                  <div className="colCardText">
                    We stay ahead of the curve, utilizing the most cutting-edge
                    technologies to ensure your web development aligns with
                    industry trends.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-implementation.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Unique Solutions</div>
                  <div className="colCardText">
                    Tailored to your specific requirements.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Powerful Strategies</div>
                  <div className="colCardText">
                    Boosting your digital presence.
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="security"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Delivery Just On Time</div>
                  <div className="colCardText">Timely, every time.</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3>
          Your business deserves a tech-savvy partner for application
          development, and Toplo Technology is ready to deliver your vision into
          the hands of your users.
        </h3>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default ApplicationDev;
