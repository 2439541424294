import React from "react";
import FooterComponent from "../../footerComponent";
import Header from "../../headerComponent/header";
import "./notfound.css";

function NotFoundPage() {
  return (
    <div>
      <Header />
      <div className="container pageNotFound">
        <h1>Page Not found</h1>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default NotFoundPage;
