import React from "react";
import Header from "../../headerComponent/header";
import FooterComponent from "../../footerComponent";
import "./cloudServices.css";

function CloudServices() {
  return (
    <div>
      <Header />
      <div className="container servicesWrraper">
        <div data-aos="fade-up" data-aos-delay="300">
          <div>
            <div className="serviceSectionHeader">Cloud Services!</div>
          </div>
          <h3>
            Cloud technology is the backbone of modern businesses, offering
            scalability, reliability, and security. Toplo Technology is your
            partner in harnessing the power of the cloud to drive your
            operations to new heights.
          </h3>
        </div>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Our Approach</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/blockchain.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cloud Strategy Development</div>
                  <div className="colCardText">
                    Tailored cloud roadmaps for your unique needs.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-modernization.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">
                    Data Migration and Management
                  </div>
                  <div className="colCardText">
                    Seamlessly move and manage your data in the cloud.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/cloud-development.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">
                    Cloud Application Development
                  </div>
                  <div className="colCardText">
                    Building cloud-native solutions for your business.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Technology Stack</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-infrastructure.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">AWS, Azure, Google Cloud</div>
                  <div className="colCardText">
                    Leading cloud platforms for hosting and storage.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Microservices Architecture</div>
                  <div className="colCardText">
                    Scaling applications with ease.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Data Security Protocols</div>
                  <div className="colCardText">
                    Protecting your critical information.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/application-modernization.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Serverless Computing</div>
                  <div className="colCardText">
                    Cost-efficient and scalable solutions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Benefits</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/app-design.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Scalability</div>
                  <div className="colCardText">
                    Grow your resources as your business expands.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/web-application.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Cost-Efficiency</div>
                  <div className="colCardText">
                    Pay only for what you use with flexible pricing.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Data Security</div>
                  <div className="colCardText">
                    Protect your valuable information with advanced protocols.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-launching.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Business Continuity</div>
                  <div className="colCardText">
                    Ensure operations even in unforeseen circumstances.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container" data-aos="fade-up" data-aos-delay="300">
            <div className="introHeadingDiv">
              <div className="serviceSectionHeader">Why Choose Us</div>
            </div>
            <div className="row  cardRowWrraper">
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/webservice-ecommerce.svg"
                      alt="E-commerce Solutions"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Latest Technologies</div>
                  <div className="colCardText">
                    Leveraging cutting-edge cloud platforms.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/devops-implementation.svg"
                      alt="marketing"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Unique Solutions</div>
                  <div className="colCardText">
                    Tailoring cloud strategies to your business.
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/desktop-application.svg"
                      alt="webDesign"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Powerful Strategies</div>
                  <div className="colCardText">
                    Maximizing the benefits of cloud technology.
                  </div>
                </div>
              </div>

              <div
                className="col-xl-4 col-lg-4 col-md-6 col-12"
                style={{ marginBottom: "30px" }}
              >
                <div className="colCardWrraper">
                  <div className="cardIconStyle">
                    <img
                      src="/maintainance-application.svg"
                      alt="security"
                      className="cardImage"
                    />
                  </div>
                  <div className="colCardTitle">Delivery Just On Time</div>
                  <div className="colCardText">
                    Meeting project deadlines consistently.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3>
          Elevate your business to new heights with Toplo Technology’s Cloud
          Services. Unleash the power of scalable and secure cloud technology to
          enhance your operations, ensure business continuity, and drive growth.
        </h3>
      </div>
      <div className="container footerCommanDiv">
        <FooterComponent />
      </div>
    </div>
  );
}

export default CloudServices;
